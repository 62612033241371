import { Link, useLocation } from "react-router-dom";
import "./style.css";
import { AiOutlineMenu } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import { Select, Space } from "antd";
import Modal from "../Modal";
import moment from "moment";
import Logo from "../../assets/png/Spiral Logo (with Tag).png";
import ContactModal from "../ContactModal";
import CMUImg from "../../assets/png/cmulogo.jpg";
import { CiGlobe } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoCheckmark } from "react-icons/io5";
import { IoCheckmarkSharp } from "react-icons/io5";
import ContactDoc from "../../assets/docs/contact_docs.pdf";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  const locales = [
    { title: "English", short: "en" },
    { title: "Français", short: "fr" },
    { title: "Swahili", short: "sw" },
    { title: "Hausa", short: "hs" },
    { title: "አማርኛ", short: "am" },
    { title: "عربي", short: "ar" },
  ];

  let location = useLocation();
  const pathname = location.pathname;
  const [showNav, setshowNav] = useState(false);
  const [modal, setmodal] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [showlag, setshowlag] = useState(false);
  const [selectedLag, setselectedLag] = useState("English");
  const [localLang, setlocalLang] = useState("English");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 30) {
        setshowNav(true);
      } else {
        setshowNav(false);
      }
    });

    let storedLang = localStorage.getItem("lang");

    setlocalLang(storedLang);

    return () => {};
  }, [selectedLag]);

  // const root = document.getElementById("root");
  // console.log(root, "---root");

  // if (showlag) {
  //   root.addEventListener("click", () => {
  //     setshowlag(!showlag);
  //     console.log("clicked");
  //   });
  // body.addEventListener("click", () => {
  //   setshowlag(false);
  // });
  // }

  return (
    <>
      <div
        className={
          // "w-full  fixed navbar z-50 bg-white "
          showNav
            ? `w-full fixed navbar z-50 bg-white drop-shadow-sm border-b-[1px] border-b-[#e8effa]`
            : `w-full fixed navbar z-50 bg-white  border-b-[1px] border-b-[#e8effa]`
        }
        // className={"w-full px-3 py-5 fixed navbar z-50 bg-white drop-shadow-md"}
      >
        <div className="w-full z-40 bg-[#e8effa] py-2">
          <div className="flex relative flex-row items-center justify-end w-[96%] md:w-[70%] mx-auto">
            <CiGlobe color="#3B82F6" />
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => setshowlag(!showlag)}
            >
              <div className="text-[14px] pl-2 ">
                {localLang ? localLang : selectedLag}
              </div>
              <div className="">
                <MdOutlineKeyboardArrowDown />
              </div>
            </div>
            {showlag && (
              <div className="absolute pt-2 top-[30px] rounded-md animate__animated animate__fadeInUp w-[200px] h-[240px] bg-white overflow-hidden shadow-md bottom-0">
                {locales.map(({ title, short }) => (
                  <div
                    className={
                      localLang == title
                        ? "px-2 bg-[#e8effa] flex items-center justify-between  hover:bg-[#e8effa] cursor-pointer py-2"
                        : "px-2 flex items-center justify-between  hover:bg-[#e8effa] cursor-pointer py-2"
                    }
                    key={short}
                    onClick={() => {
                      setselectedLag(title);
                      i18n.changeLanguage(short);
                      setshowlag(!showlag);
                      setlocalLang(title);
                      // save the language in the locale storage
                      localStorage.setItem("lang", title);
                    }}
                  >
                    <div className="text-[15px]"> {title}</div>
                    {localLang == title && (
                      <div className="">
                        <IoCheckmarkSharp size={16} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            <Select
              defaultValue="English"
              style={{
                width: 90,
                display: "none",
                color: "#3B82F6",
                background: "#e8effa !important",
              }}
              onChange={(e) => {
                i18n.changeLanguage(e);
              }}
              options={[
                {
                  value: "en",
                  label: "English",
                },
                {
                  value: "fr",
                  label: "Français",
                },
              ]}
            />
          </div>
        </div>
        {showmodal && (
          <ContactModal showmodal={showmodal} setshowmodal={setshowmodal} />
        )}

        {modal && (
          <Modal
            closeModal={setmodal}
            showmodal={showmodal}
            setshowmodal={setshowmodal}
          />
        )}
        <div className="px-1 md:px-3 py-1 md:py-1 w-[96%] md:w-[80%] mx-auto flex flex-row items-center justify-between ">
          <div className="flex relative overflow-hidden items-center justify-center ">
            <Link
              to="/"
              className="flex ml-[-10px] md:ml-0 flex-row items-center justify-items-start w-[100px] h-[25px] md:w-[120px] md:h-[60px]"
            >
              <img
                src={Logo}
                className="w-auto h-auto"
                style={{
                  scale: "0.6",
                }}
              />{" "}
              {/* </div> */}
              {/* <div className="w-[30px] hidden h-[30px] rounded-full overflow-hidden items-center justify-center border-[1px] border-secondary"></div> */}
              {/* <div
            className={
              pathname == "/services" && !showNav
                ? `brand text-[27px] font-semibold mx-2 text-white font-Kanit`
                : pathname == "/services" && showNav
                ? `brand text-[27px] font-semibold mx-2 text-zinc-800 font-Kanit`
                : `brand text-[27px] font-semibold mx-2 text-zinc-800 font-Kanit`
            }
          >
            Spiral Tech
          </div> */}
            </Link>
            {/* <div className="mt-[-14px] ml-[-20px] text-[25px]"> /</div> */}
            <Link
              to="https://www.africa.engineering.cmu.edu/impact/industry-innovation-lab/index.html"
              className="flex "
              target="_blank"
            >
              <img
                src={CMUImg}
                className="pt-2 ml-[-18px] mt-[-14px] w-[90px] h-[70px]"
              />
            </Link>
          </div>

          <div className="flex flex-row items-center ">
            <div className="hidden md:hidden lg:flex items-center">
              {/* <Link
              className={
                pathname == "/services" && !showNav
                  ? "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-white  transition-all p-2"
                  : pathname == "/services" && !showNav
                  ? "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-zinc-800  transition-all p-2"
                  : "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-zinc-800  transition-all p-2"
              }
              to="/"
            >
              Carnegie Mellon University
            </Link> */}
              <Link
                className={
                  pathname == "/services" && !showNav
                    ? "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-white  transition-all p-2"
                    : pathname == "/services" && !showNav
                    ? "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-zinc-800  transition-all p-2"
                    : "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-zinc-800  transition-all p-2"
                }
                to="/"
              >
                {/* Home */}
                {t("nav.home")}
              </Link>
              <Link
                className={
                  pathname == "/services" && !showNav
                    ? "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-white  transition-all p-2"
                    : pathname == "/services" && showNav
                    ? "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-zinc-800  transition-all p-2"
                    : "cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-zinc-800  transition-all p-2"
                }
                // target="_blank"
                to="/features"
                // to="https://spiraldocs.vercel.app/"
              >
                {/* Developer */}
                {t("nav.developer")}
              </Link>

              <div
                onClick={() => setshowmodal(!showmodal)}
                // href={ContactDoc}
                // href="mailto:toguntad@andrew.cmu.edu"
                className="cursor-pointer mr-16 hover:text-blue-500 text-[15px] font-normal text-zinc-800  transition-all p-2"
              >
                {/* Contact Us */}
                {t("nav.contact")}
              </div>
              <Link
                // text-zinc-800
                className={
                  "cursor-pointer mr-12 px-3 py-1 text-blue-500 border-[1px] rounded-sm border-blue-500  text-[15px] font-normall  transition-all"
                }
                to="https://app.spiral.systems/"
              >
                {/* Login */}
                {t("nav.login")}
              </Link>
              <Link
                //  text-zinc-800
                className={
                  "cursor-pointer px-2 py-1 text-white border-[1px] border-blue-500 rounded-sm bg-blue-500 mr-16 text-[15px] font-normall transition-all "
                }
                to="https://app.spiral.systems/createaccount"
              >
                {/* Get started */}
                {t("nav.getstarted")}
              </Link>
            </div>

            <div className="hidden items-center">
              <a
                href="https://app.spiral.systems/"
                className={
                  pathname == "/services" && !showNav
                    ? "hidden md:block lg:block text-[14px] border-[1px] border-white text-light px-4 py-2 text-white rounded-md cursor-pointer"
                    : "hidden md:block lg:block text-[14px] border-[1px] border-gray-700 text-light px-4 py-2 rounded-md cursor-pointer"
                }
              >
                Login
              </a>
              <a
                href="https://app.spiral.systems/createaccount"
                target={"_blank"}
                className={
                  "bg-gray-900 text-white ml-4 text-[14px] px-4 py-2 rounded-md border-[1px] hidden md:block lg:block border-gray-700 cursor-pointer"
                }
              >
                Get Started
              </a>
            </div>
            <div className="lg:hidden ml-2" onClick={() => setmodal(true)}>
              <AiOutlineMenu
                size={30}
                color={
                  pathname == "/service" && showNav
                    ? "#000"
                    : pathname == "/services" && !showNav
                    ? "#fff"
                    : "#000"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
