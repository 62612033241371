import BGImg from "../../assets/png/v300batch2-nunoon-13.jpg";
import GMail from "../../assets/png/icons8-gmail-144.png";
import ComingSoon from "../../assets/png/5113755.jpg";
import { IoMailUnread } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout";
const Developer = () => {
  const { t, i18n } = useTranslation();
  const newData = t("newData", { returnObjects: true });
  const navigate = useNavigate();
  const locales = [
    { title: "English", short: "en" },
    { title: "Français", short: "fr" },
    { title: "Swahili", short: "sw" },
    { title: "Hausa", short: "hs" },
    { title: "አማርኛ", short: "am" },
    { title: "عربي", short: "ar" },
  ];
  return (
    <Layout>
      <div className="flex bg-white items-center h-screen w-screen justify-center">
        <div
          className="h-screen absolute left-0 w-screen"
          style={{
            background: `url(${BGImg})`,
            backgroundPosition: "center",
            backgroundRepeat: "center",
            backgroundSize: "cover",
            opacity: 0.3,
            backgroundAttachment: "fixed",
          }}
        ></div>
        <div className="grid grid-cols-1 mt-10 md:grid-cols-2 w-[98%] md:w-[80%] lg:w-[70%] z-40 md:bg-white mx-auto md:shadow-lg rounded-md">
          <div
            className="h-[auto] md:h-[80vh] hidden md:flex overflow-hidden items-center justify-center"
            style={{}}
          >
            <img src={ComingSoon} alt="Coming soon" />
          </div>
          <div className="h-[auto] md:h-[80vh] flex flex-col items-center justify-center p-4">
            <div
              className="cursor-pointer hidden text-blue-600 underline"
              onClick={() => navigate(-1)}
            >
              {t("developer.nav")}
            </div>
            <div className="text-[20px] font-bold pt-6">
              {t("developer.title")}
              {/* Welcome to Spiral Developer Page */}
            </div>
            <div className="">
              <div className="text-left border-b-[1px] pb-1 border-gray-400 pt-2 font-semibold">
                {t("developer.caption")}
              </div>
              <div className="grid grid-cols-1">
                {newData.map(({ id, title, desc }) => (
                  <div className="mt-4 text-justify" key={id}>
                    <span className="font-semibold">{title} </span> {desc}
                  </div>
                ))}

                {/* <div className="mt-4 hidden">
                <span className="font-semibold">
                  ⚡ Next-Gen IoT Compatibility:
                </span>
                Connect with the future! Leverage our IoT-friendly
                infrastructure to develop applications that bring intelligence
                to every device. From smart meters to energy-efficient
                appliances, the possibilities are endless.
              </div>
              <div className="mt-4">
                <span className="font-semibold">
                  📊 Data Analytics Playground:
                </span>{" "}
                Unleash the potential of data! Explore our analytics playground
                to gain valuable insights into energy consumption patterns,
                optimize efficiency, and contribute to a sustainable future.
              </div>
              <div className="mt-4">
                <span className="font-semibold">
                  🛠️ Powerful Tools & Resources:
                </span>
                Equip yourself with the tools you need for success. Our
                developer resources, documentation, and sample codes ensure you
                have everything at your fingertips to create unparalleled energy
                solutions.{" "}
              </div> */}
              </div>

              <div className="mt-6">
                <div className="text-center font-semibold">
                  {t("developer.bottom")}
                </div>
                <div
                  className="
              flex items-center justify-center mt-1 border-1 border-gray-900"
                >
                  <Link
                    target="_blank"
                    to="https://www.linkedin.com/company/spiralsys/about/"
                    className="h-[50px] w-[50px] flex items-center justify-center rounded-full"
                  >
                    <FaLinkedin color="dodgerblue" size={30} />
                  </Link>
                  <a
                    href="mailto:hello@spiral.systems"
                    className="h-[35px] ml-4 w-[35px] flex items-center justify-center rounded-full"
                  >
                    {/* <img src={GMail} className="h-full w-full" /> */}
                    <IoMailUnread color="#000000" size={32} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Developer;
